<template>
    <div id="first-page"></div>
</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>