<template>
    <div id="first-page">
        <desktop-view></desktop-view>
        <mobile-view></mobile-view>
    </div>
</template>

<script>
import DesktopView from '../components/first-page/desktop-view.vue'
import MobileView from '../components/first-page/mobile-view.vue'
export default {
    components:{DesktopView,MobileView}
}
</script>

<style scoped>
#first-page{
    width:100vw;
    height:fit-content;
}
</style>